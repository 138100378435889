import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pb-0 shadow1 white rounded",attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"custom-data-table elevation-0",attrs:{"headers":_vm.chequeInfoHeaders,"items":_vm.dishonoured,"search":_vm.searchCheque},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"dense":"","color":"white","elevation":0}},[_c(VToolbarTitle,{staticClass:"subtitle-2"},[_vm._v("Dishonoured Cheque Information List")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VForm,{staticClass:"custom-form"},[_c(VTextField,{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search Cheque","append-icon":"mdi-magnify"},model:{value:(_vm.searchCheque),callback:function ($$v) {_vm.searchCheque=$$v},expression:"searchCheque"}})],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }